<template>
    <div>
        <h1>Assets</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import Options from './asset_rpt_opt.vue'
    import Fields from './utils/Fields.vue'
    import Results from "./utils/Results";

    export default {
        name: "asset_rpt",
        components: {
            Options,
            Fields,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    label: { val: '', qsField: '[strings][a.asset_label]' },
                    cdiLocationID: { val: 0, qsField: '[numbers][a.cdi_location_id]' },
                    type: { val: 0, qsField: '[numbers][a.asset_type]' },
                    user: { val: '', qsField: '[numbers][a.user_id]'},
                    enabled: { val: 1, qsField: '[isnot][a.asset_disable]' },
                },
                groupByFields: {},
                groupBy: false,
                dataFields: {
                    asset_id: { label: 'ID', checked: true },
                    asset_label: { label: 'Label', checked: true },
                    asset_type: { label: 'Type', checked: true },
                    cdi_location: { label: 'Location', checked: true },
                    user: { label: 'User', checked: true },
                    asset_model: { label: 'Model' },
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Assets']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>