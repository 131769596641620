<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class='col-md-3 col-sm-4 col-xs-6'>
                <label>Label</label><input type="text" class="form-control" v-model="state.optionFields.label.val">
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="cdi_select form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS"
                            v-bind:key="cdiLocationID" :value="cdiLocationID">{{ cdiLocation }}
                    </option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Type</label>
                <select class="cdi_select form-control" v-model="state.optionFields.type.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLookupID, assetType] in cache.assetCache.ASSET_TYPE" v-bind:key="cdiLookupID"
                            :value="cdiLookupID">{{ assetType }}
                    </option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6'>
                <label>Operator</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.user.val"/>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type='checkbox' :true-value="1" :false-value="0" v-model="state.optionFields.enabled.val" > Enabled</label>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from '@/store/Report.store.js'
import cdiLocationCache from '@/cache/cdiLocation.cache.js';
import assetCache from '@/cache/asset.cache.js';
import Typeahead from "@/components/utils/typeaheads/Typeahead";

export default {
    name: "Options",
    components: {Typeahead},
    data() {
        return {
            state: store.state,
            toggleHide: true,
            cache: {
                cdiLocationCache,
                assetCache,
            },
        }
    },
    methods: {
        toggleMoreFilters: function () {
            this.toggleHide = !this.toggleHide;
        },
    }
}
</script>

<style scoped>

</style>